<template>
  <div class="tw-min-h-[60vh] tw-flex tw-items-center tw-justify-center">
    <div v-if="!cameraAberta" class="tw-h-full">
      <div
        v-if="!validacaoConcluida"
        class="tw-bg-[#3849AB]/10 tw-rounded-full d-flex flex-column justify-content-center align-items-center tw-h-full tw-p-24 tw-p-2 md:tw-py-44"
        @click="abrirCamera"
      >
        <div class="tw-bg-[#3849AB] tw-p-4 tw-rounded-full">
          <FeatherIcon icon="VideoIcon" size="32" class="mt-0 tw-text-white" />
        </div>
        <h5 class="text-center text-primary my-1 text-center font-weight-bolder">
          Centralize o rosto dentro <br />
          da câmera e siga as instruções
        </h5>
      </div>
      <span
        v-else
        class="tw-bg-[#3acf78]/10 tw-rounded-full d-flex flex-column justify-content-center align-items-center tw-h-full tw-p-24 md:tw-py-44"
      >
        <div class="tw-bg-[#3acf78] tw-p-4 tw-rounded-full">
          <FeatherIcon icon="UserCheckIcon" size="32" class="mt-0 tw-text-white" />
        </div>
        <h4 class="mb-1 mt-2 text-center font-weight-bolder tw-text-[#3acf78] tw-text-base">{{ mensagem }}</h4>
      </span>
    </div>
    <div v-else class="face-liveness">
      <div v-show="!isBusy">
        <h5 class="message text-center tw-mb-12">
          <span class="alert alert-primary py-1 px-2 rounded">
            {{ mensagem }}
          </span>
        </h5>
        <div class="video-container">
          <video ref="video" id="webcam" autoplay playsinline muted></video>
          <div v-if="instrucaoAtual" class="overlay">
            <span v-if="instrucaoAtual === 'direita'" :style="{ animation: 'slide-direita 1s infinite' }">
              <feather-icon icon="ChevronLeftIcon" size="36" />
            </span>
            <span v-if="instrucaoAtual === 'esquerda'" :style="{ animation: 'slide-esquerda 1s infinite' }">
              <feather-icon icon="ChevronRightIcon" size="36" />
            </span>
            <span v-if="instrucaoAtual === 'cima'" :style="{ animation: 'slide-cima 1s infinite' }">
              <feather-icon icon="ChevronUpIcon" size="36" />
            </span>
            <span v-if="instrucaoAtual === 'baixo'" :style="{ animation: 'slide-baixo 1s infinite' }">
              <feather-icon icon="ChevronDownIcon" size="36" />
            </span>
          </div>
        </div>
      </div>
      <div v-show="isBusy" class="text-center">
        <p class="tw-text-lg">Carregando...</p>
        <b-skeleton
          type="avatar"
          class="tw-rounded-full d-flex flex-column justify-content-center align-items-center tw-h-full tw-p-24 md:tw-p-44 md:tw-py-44"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    data() {
      return {
        stream: null,
        instrucoes: ['direita', 'esquerda', 'cima', 'baixo'],
        instrucaoAtual: 'direita',
        mensagem: '',
        intervalId: null,
        errors: undefined,
        hasErrors: false,
        validacaoConcluida: false,
        cameraAberta: false,
        isBusy: false,
      }
    },
    methods: {
      async iniciarStream() {
        this.errors = undefined
        this.hasErrors = true
        this.validateField()
        this.stream = await navigator.mediaDevices.getUserMedia({ video: true })
        this.$refs.video.srcObject = this.stream
        this.iniciarValidacaoContinua()
      },
      async enviarFrame() {
        this.isBusy = false

        const canvas = document.createElement('canvas')
        canvas.width = this.$refs.video.videoWidth
        canvas.height = this.$refs.video.videoHeight
        canvas.getContext('2d').drawImage(this.$refs.video, 0, 0)

        const dataUrl = canvas.toDataURL('image/jpeg')
        const blob = await fetch(dataUrl).then((res) => res.blob())

        const formData = new FormData()
        formData.append('file', blob, 'frame.jpg')
        formData.append('instrucao', this.instrucaoAtual)

        const response = await useJwt.post('aws/verificarFaceLiveness', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        this.mensagem = response.data.mensagem

        if (response.data.sucesso) {
          const proximaInstrucaoIndex = this.instrucoes.indexOf(this.instrucaoAtual) + 1
          if (proximaInstrucaoIndex < this.instrucoes.length) {
            this.instrucaoAtual = this.instrucoes[proximaInstrucaoIndex]
          } else {
            this.mensagem = 'Validação facial concluída com sucesso!'
            this.instrucaoAtual = null
            clearInterval(this.intervalId)
            this.cameraAberta = false
            this.errors = undefined
            this.hasErrors = false
            this.validacaoConcluida = true
            this.validateField()
          }
        }
      },
      iniciarValidacaoContinua() {
        this.intervalId = setInterval(() => {
          this.enviarFrame()
        }, 2000)
      },
      validateField() {
        // Emite o estado de erro para o componente pai
        this.$emit('validaProvaDeVida', {
          hasErrors: this.hasErrors,
          errors: this.errors,
        })
      },
      async abrirCamera() {
        this.cameraAberta = true
        this.isBusy = true
        await this.iniciarStream()
      },
      async fecharCamera() {
        this.cameraAberta = false
        this.isBusy = false
        this.stream.getTracks().forEach((track) => track.stop())
      },
    },
    beforeDestroy() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop())
      }
    },
    beforeUnmount() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop())
      }
    },
  }
</script>

<style>
  .face-liveness {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .video-container {
    position: relative;
    height: 50vh;
    width: 50vh;
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid #3949ab;
    transform: scaleX(-1);
  }

  .face-liveness video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que o vídeo preencha o contêiner */
    border-radius: 50% / 40%; /* Ajuste para forma oval */
  }

  .overlay {
    position: absolute;
    bottom: 10%;
    left: 50%;
    height: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background-color: rgba(57, 73, 171, 0.9);
    color: #fff;
    transform: translateX(-50%);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* Adiciona sombra ao overlay */
  }

  .overlay span {
    animation: slide 1s infinite;
  }

  @keyframes slide-esquerda {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-direita {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-cima {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slide-baixo {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
</style>
