<template>
  <div>
    <div class="camera-container text-center d-flex align-items-center justify-content-center text-center">
      <video v-if="isCameraOpen" ref="video" autoplay playsinline muted></video>
      <img v-if="!isCameraOpen && hasSelfie" :src="selfie" class="img-fluid rounded" />
    </div>
    <div class="mt-1 d-flex align-items-center justify-content-center text-center">
      <b-badge class="alert alert-primary text-wrap">
        <p class="font-weight-bolder text-center">
          Tire uma foto segurando o documento de forma que a sua face e o documento estejam visíveis.
        </p>
      </b-badge>
    </div>
    <div class="mt-1 d-flex align-items-center justify-content-center text-center">
      <span v-if="isCameraOpen && !isBusy" @click="capturePhoto" class="alert alert-primary mb-0 p-2 rounded-circle">
        <FeatherIcon size="20" icon="CameraIcon" />
      </span>
      <span v-else-if="hasSelfie && !isBusy" @click="clearPhoto" class="alert alert-danger p-2 mb-0 rounded-circle">
        <FeatherIcon size="20" icon="Trash2Icon" />
      </span>
      <span v-else class="alert alert-secondary p-1 mb-0 rounded-circle">
        <b-spinner small class="loading-size" label="Small Spinner" />
      </span>
    </div>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    data() {
      return {
        isCameraOpen: true,
        hasSelfie: false,
        selfie: null,
        hasErrors: false,
        errors: undefined,
        isBusy: false,
      }
    },

    async mounted() {},
    async beforeUpdate() {},
    async beforeUnmount() {
      this.stopCameraStream()
    },
    methods: {
      async openCamera() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true })
          this.$refs.video.srcObject = stream
        } catch (error) {
          console.error('Erro ao acessar a câmera:', error)
        }
      },
      capturePhoto() {
        const video = this.$refs.video
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        canvas.getContext('2d').drawImage(video, 0, 0)
        this.selfie = canvas.toDataURL('image/png')
        this.hasSelfie = true
        this.isCameraOpen = false
        this.errors = null
        this.stopCameraStream()

        this.validateField() // Chame validateField após capturar a foto
        this.enviarFoto()
      },
      clearPhoto() {
        this.selfie = null
        this.hasSelfie = false
        this.isCameraOpen = true
        this.openCamera()
        this.errors = 'Não foi detectado foto. Tente novamente!'
        this.hasErrors = true
        this.validateField() // Chame validateField após limpar a foto
      },
      stopCameraStream() {
        if (this.$refs.video.srcObject) {
          this.$refs.video.srcObject.getTracks().forEach((track) => track.stop())
        }
      },
      validateField() {
        // Emite o estado de erro para o componente pai
        this.$emit('validaDocumentosFoto', {
          hasErrors: this.hasErrors,
          errors: this.errors,
        })
      },
      async enviarFoto() {
        this.isBusy = true
        // Convertendo base64 para Blob e criando FormData
        const base64Image = this.selfie.replace(/^data:image\/png;base64,/, '')
        const byteCharacters = atob(base64Image)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'image/png' })

        const formData = new FormData()
        formData.append('file', blob, 'selfie.png')

        // Enviando a foto para o backend
        await useJwt
          .post('aws/verificarFaceDocumento', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const data = response.data

            if (data.similaridade && data.similaridade >= 80) {
              this.hasErrors = false
              this.errors = undefined
            } else {
              this.hasErrors = true
              this.errors = 'A face do documento não corresponde ao rosto capturado.'
            }
          })
          .catch(async (error) => {
            this.hasErrors = true
            this.errors = `${error.response.data.message.message}.`
            await this.validateField()
            console.error(error)
          })
          .finally(async () => {
            await this.validateField()
            this.isBusy = false
          })
      },

      beforeDestroy() {
        this.stopCameraStream()
      },
    },
  }
</script>

<style scoped>
  .camera-container video,
  .camera-container img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border: 5px solid #3949ab; /* Borda azul para destacar o enquadramento */
    transform: scaleX(-1);
  }

  .loading-size {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
</style>
