<template>
  <div class="container-contrato">
    <b-card class="custom-card py-0 tw-min-h-[70vh] tw-flex tw-items-center tw-justify-center">
      <div v-if="assinaturaConcluida" class="tw-min-h-[70vh]">
        <div class="tw-min-h-[70vh] text-center tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 p-4 tw-h-full">
          <b-icon icon="file-earmark-check" variant="success" font-scale="6" class="my-2" />
          <h2 class="text-success font-weight-bold">Assinatura concluída com sucesso!</h2>
          <p class="text-secondary">
            Parabéns! Seu contrato foi assinado com sucesso após a validação de todas as informações fornecidas.
          </p>
        </div>
      </div>

      <div v-else-if="dadosCompleto && !isBusy">
        <form-wizard
          ref="wizard"
          shape="circle"
          color="#3949ab"
          :title="null"
          :subtitle="null"
          back-button-text="Voltar"
          next-button-text="Próximo"
          finish-button-text="Finalizar"
          @on-complete="assinarContrato"
          class="wizard-container mb-0 p-0"
        >
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer d-flex align-items-center justify-content-between p-0">
              <div class="justify-content-start align-items-center">
                <wizard-button v-if="props.activeTabIndex > 0" @click.native="props.prevTab()">
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="50"
                    class="alert alert-secondary text-white rounded-circle p-1 mb-0"
                  />
                </wizard-button>
              </div>

              <div class="justify-content-end align-items-center">
                <wizard-button v-if="!props.isLastStep && !isBusy" @click.native="props.nextTab()">
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="50"
                    class="alert alert-primary text-white rounded-circle p-1 mb-0"
                  />
                </wizard-button>

                <wizard-button v-else-if="props.isLastStep" @click.native="assinarContrato" :disabled="abaErros.assinatura">
                  <feather-icon
                    icon="CheckIcon"
                    size="50"
                    class="text-white rounded-circle p-1 mb-0"
                    :class="abaErros.assinatura ? 'alert-danger' : 'alert-success'"
                  />
                </wizard-button>
              </div>
            </div>
          </template>

          <tab-content title="Dados">
            <AssinaturaContratoSMSDados :dadosCompleto="dadosCompleto" />
          </tab-content>

          <tab-content title="Documentos" :before-change="validaDocumentos">
            <div
              v-if="abaErros.documentos && validacaoDocumentos && errosStepsDocumento"
              class="alert alert-danger font-weight-bolder rounded-pill text-center p-50"
            >
              {{ errosStepsDocumento }}
            </div>
            <AssinaturaConstratoSMSDocumentos @validaDocumentos="validaDocumentos" :dadosCompleto="dadosCompleto" />
          </tab-content>

          <tab-content title="Foto com documento" :before-change="validaDocumentosFoto">
            <div
              v-if="abaErros.fotoDocumento && validacaoDocumentosFoto && errosStepsDocumentoFoto"
              class="alert alert-danger font-weight-bolder rounded-pill text-center p-50"
            >
              {{ errosStepsDocumentoFoto }}
            </div>
            <AssinaturaContratoSMSFotoDocumento ref="componenteFotoDocumento" @validaDocumentosFoto="validaDocumentosFoto" />
          </tab-content>

          <tab-content title="Prova de Vida" :before-change="validaProvaDeVida">
            <div
              v-if="abaErros.provaDeVida && validacaoProvaDeVida && errosStepsProvaDeVida"
              class="alert alert-danger font-weight-bolder rounded-pill text-center p-50"
            >
              {{ errosStepsProvaDeVida }}
            </div>
            <AssinaturaContratoSMSProvaDeVida ref="componenteProvaVida" @validaProvaDeVida="validaProvaDeVida" />
          </tab-content>

          <tab-content title="Validar Assinatura" :before-change="validaAssinatura">
            <div
              v-if="abaErros.assinatura && validacaoAssinatura && errosStepsAssinatura"
              class="alert alert-danger font-weight-bolder rounded-pill text-center p-50"
            >
              {{ errosStepsAssinatura }}
            </div>
            <AssinaturaContratoSMSAssinatura
              @validaAssinatura="validaAssinatura"
              :dadosCompleto="dadosCompleto"
              :codigoValidacao="codigoValidacao"
              @assinarContrato="assinarContrato"
            />
          </tab-content>
        </form-wizard>
      </div>

      <div v-else-if="isBusy">
        <div class="text-center tw-flex tw-flex-col justify-content-center align-items-center tw-min-h-[70vh] tw-gap-4">
          <h3 class="text-primary">Carregando...</h3>
          <b-spinner type="grow" size="200" class="text-primary" style="width: 3rem; height: 3rem" />
        </div>
      </div>

      <div v-else class="text-center tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center p-1">
        <feather-icon icon="AlertOctagonIcon" size="100" class="alert alert-danger text-white rounded-circle p-2" />
        <h1 class="text-danger">Contrato não encontrado</h1>
        <p>Por favor, verifique o link de acesso e tente novamente</p>
      </div>
    </b-card>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber } from '@/utils/Functions/Formatacao/Formatacao'
  import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import AssinaturaContratoSMSDados from './AssinaturaContratoSMSDados.vue'
  import AssinaturaConstratoSMSDocumentos from './AssinaturaContratoSMSDocumentos.vue'
  import AssinaturaContratoSMSFotoDocumento from './AssinaturaContratoSMSFotoDocumento.vue'
  import AssinaturaContratoSMSProvaDeVida from './AssinaturaContratoSMSProvaDeVida.vue'
  import AssinaturaContratoSMSAssinatura from './AssinaturaContratoSMSAssinatura.vue'
  import saveAs from 'file-saver'

  export default {
    components: {
      AssinaturaContratoSMSDados,
      AssinaturaConstratoSMSDocumentos,
      AssinaturaContratoSMSFotoDocumento,
      AssinaturaContratoSMSProvaDeVida,
      AssinaturaContratoSMSAssinatura,
      FormWizard,
      TabContent,
      WizardButton,
      formatarParaNumber,
    },
    data() {
      return {
        dadosCompleto: null,
        isBusy: false,
        idParam: null,
        contrato: null,
        errosStepsDocumento: 'Não foi detectado documento.',
        errosStepsDocumentoFoto: 'Não foi detectado documento com foto.',
        errosStepsProvaDeVida: 'Não foi detectado prova de vida.',
        errosStepsAssinatura: 'Nenhuma assinatura foi detectada.',
        abaErros: {
          documentos: true,
          fotoDocumento: true,
          provaDeVida: true,
          assinatura: true,
        },
        validacaoDocumentos: false,
        validacaoDocumentosFoto: false,
        validacaoProvaDeVida: false,
        validacaoAssinatura: false,
        codigoValidacao: null,
        assinaturaConcluida: false,
      }
    },
    created() {
      this.dadosCompleto = null
    },
    async mounted() {
      await this.buscaDadosPeloCodigo()
    },
    methods: {
      async buscaDadosPeloCodigo() {
        this.isBusy = true
        this.idParam = new URLSearchParams(window.location.search).get('id')
        this.codigoValidacao = formatarParaNumber(this.idParam)
        await this.validarCodigo(this.codigoValidacao)
      },
      validarCodigo(codigo) {
        useJwt
          .get(`assinatura/getContratoParaAssinarSMS/${codigo}`)
          .then((response) => {
            const reponse = response.data
            this.dadosCompleto = this.$jwtDecode(reponse.token).dados
          })
          .catch((error) => {
            console.error('error', error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      async assinarContrato() {
        this.isBusy = true

        const parametros = {
          idContrato: this.dadosCompleto.id,
          url: window.location.origin,
        }

        useJwt
          .post(`assinatura/assinarContratoViaSMS`, parametros)
          .then(async (response) => {
            this.assinaturaConcluida = true
            const data = response.data
            const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
            const blob = new Blob([buffer], { type: data.ContentType })
            saveAs(blob, 'Documento.pdf')
            const url = window.URL.createObjectURL(blob)
            window.open(url, '_blank')
          })
          .catch((error) => {
            console.error('error', error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      async validaDocumentos(validacao) {
        this.validacaoDocumentos = true

        if (validacao) {
          this.abaErros.documentos = validacao.hasErrors
          this.errosStepsDocumento = validacao.errors || undefined
        }

        if (!this.abaErros.documentos) {
          this.$refs.componenteFotoDocumento.openCamera()
        }

        return !this.abaErros.documentos
      },
      async validaDocumentosFoto(validacao) {
        this.validacaoDocumentosFoto = true

        if (validacao) {
          this.abaErros.fotoDocumento = validacao.hasErrors
          this.errosStepsDocumentoFoto = validacao.errors || undefined
        }

        return !this.abaErros.fotoDocumento
      },
      async validaProvaDeVida(validacao) {
        this.validacaoProvaDeVida = true

        if (validacao) {
          this.abaErros.provaDeVida = validacao.hasErrors
          this.errosStepsProvaDeVida = validacao.errors || undefined
        }

        if (!this.abaErros.provaDeVida) {
          this.$refs.componenteProvaVida.fecharCamera()
        }

        return !this.abaErros.provaDeVida
      },
      async validaAssinatura(validacao) {
        this.validacaoAssinatura = true
        if (validacao) {
          this.abaErros.assinatura = validacao.hasErrors
          this.errosStepsAssinatura = validacao.errors || undefined
        }

        return !this.abaErros.assinatura
      },
    },
  }
</script>

<style>
  .my-custom-tab {
    /* Estilos personalizados */
    background-color: transparent;
    border: none;
  }

  .vue-form-wizard .navbar .navbar-nav > li > a.wizard-btn,
  .vue-form-wizard button.wizard-btn {
    padding: 0px !important;
    min-width: 0px !important;
  }

  .wizard-header {
    padding: 0px !important;
  }

  .card-body {
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* Estilização padrão do card */
  .custom-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 50vw;
    width: 40vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Media query para telas menores que 576px (mobile) */
  @media (max-width: 575.98px) {
    .wizard-container {
      height: 100% !important;
    }
    .custom-card {
      /* Remove as estilizações no mobile */
      box-shadow: none;
      border-radius: 0;
      border: none;
      min-height: 100vh;
      max-height: 100%;
      max-width: 100vw;
      width: 100%;
    }

    .vue-form-wizard .wizard-tab-content {
      padding: 0px 16px;
    }
    .vue-form-wizard.md .wizard-icon-circle {
      width: 50px;
      height: 50px;
    }
    .stepTitle {
      font-size: 12px !important;
    }
  }

  .wizard-container {
    height: 100%; /* Ajusta a altura total do wizard */
    display: flex;
    flex-direction: column;
  }

  .wizard-card-footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    width: 100%;
    margin: 0px !important;
    padding: 1rem 0px !important;
    z-index: 10; /* Garante que o footer fique sobre outros elementos */
  }

  .wizard-container .wizard-nav {
    position: sticky;
    padding: 1rem 0px !important;
    top: 0;
    background-color: white;
    z-index: 10;
  }

  .card-body {
    padding: 0px 1rem !important;
  }

  .tab-content {
    overflow-y: auto;
    flex: 1;
  }

  .container-contrato {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
</style>
