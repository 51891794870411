<template>
  <div class="d-flex align-items-center justify-content-center flex-column text-center w-100 tw-min-h-[60vh]">
    <b-card class="border p-2">
      <validation-observer ref="validacaoAssinatura">
        <form>
          <b-row>
            <b-col md="12">
              <b-form-group label="CPF" label-for="cpfParaValidacao">
                <validation-provider #default="{ errors }" name="CPF" rules="required">
                  <cleave
                    id="cpfParaValidacao"
                    type="text"
                    placeholder="Insira o CPF"
                    v-model="cpfParaValidacao"
                    :raw="false"
                    :options="options.cleaveCPF"
                    class="form-control"
                    :disabled="isBusy"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Código" label-for="codigoParaValidacao">
                <validation-provider #default="{ errors }" name="Código" rules="required|length:6">
                  <b-form-input
                    id="codigoParaValidacao"
                    type="number"
                    placeholder="Insira o Código"
                    v-model="codigoParaValidacao"
                    :raw="false"
                    class="form-control"
                    :disabled="isBusy"
                    maxlength="6"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button pill variant="primary" class="py-1 px-2" @click="validarInformacoes" :disabled="isBusy">
                Assinar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import { required, length } from '@validations'

  export default {
    props: {
      codigoValidacao: {
        type: Number,
      },
      dadosCompleto: {
        type: Object,
      },
    },
    components: {
      length,
      required,
      ValidationObserver,
      ValidationProvider,
      Cleave,
    },
    data() {
      return {
        codigoParaValidacao: null,
        cpfParaValidacao: '',
        isBusy: false,
        codigo: null,
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
          },
        },
        hasErrors: false,
        errors: undefined,
      }
    },

    methods: {
      validarInformacoes() {
        this.isBusy = true

        let cpfValidado
        let codigoValidado
        if (this.cpfParaValidacao == this.dadosCompleto.segurado.tomador.cpf) cpfValidado = true
        if (this.codigoParaValidacao == this.codigoValidacao) codigoValidado = true

        if (cpfValidado && codigoValidado) {
          this.isBusy = false
          this.hasErrors = false
          this.errors = undefined
          this.validateField()
          this.$emit('assinarContrato')
        } else {
          this.isBusy = false
          this.hasErrors = true
          this.errors = 'CPF ou Código inválido(s).'
          this.validateField()
        }
      },
      validateField() {
        this.$emit('validaAssinatura', {
          hasErrors: this.hasErrors,
          errors: this.errors,
        })
      },
    },
  }
</script>
