<template>
  <div class="">
    <div>
      <b-card class="border shadow-lg py-1">
        <b-row class="tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-flex-row tw-gap-6">
          <b-col class="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <b-avatar size="100" :src="dadosTomador.foto" variant="light-secondary" />
          </b-col>
          <b-col class="tw-flex tw-flex-col tw-justify-center tw-items-start">
            <h5 class="tw-text-base md:tw-text-lg tw-mb-0.5">Nome</h5>
            <p class="tw-text-base md:tw-text-lg tw-mb-0.5">{{ dadosTomador.nome }}</p>
            <h5 class="tw-text-base md:tw-text-lg tw-mb-0.5">Telefone</h5>
            <p class="tw-text-base md:tw-text-lg tw-mb-0.5">{{ dadosTomador.telefone }}</p>
            <h5 class="tw-text-base md:tw-text-lg tw-mb-0.5">CPF</h5>
            <p class="tw-text-base md:tw-text-lg tw-mb-0.5">{{ dadosTomador.cpf }}</p>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div>
      <b-card class="border py-1 mb-0">
        <b-row>
          <b-col cols="12">
            <h4 class="font-weight-bolder text-lg d-flex align-items-center justify-content-center mb-1">Contrato</h4>
            <hr />
          </b-col>
          <b-col cols="12" class="mb-25 d-flex flex-column">
            <p class="mb-0 tw-text-base md:tw-text-lg tw-font-bold">Numero do Contrato:</p>
            <span class="">
              {{ dadosContrato.codigo }}
            </span>
          </b-col>
          <b-col cols="12" class="mb-25 d-flex flex-column">
            <p class="mb-0 tw-text-base md:tw-text-lg tw-font-bold">Valor do Contrato:</p>
            <span class="">
              {{ dadosContrato.valorContrato }}
            </span>
          </b-col>
          <b-col cols="12" class="mb-25 d-flex flex-column">
            <p class="mb-0 tw-text-base md:tw-text-lg tw-font-bold">Quantidade de Parcelas:</p>
            <span class="">
              {{ dadosContrato.quantidadeParcelas }}
            </span>
          </b-col>
          <b-col cols="12" class="mb-25 d-flex flex-column">
            <p class="mb-0 tw-text-base md:tw-text-lg tw-font-bold">Data do Contrato:</p>
            <span class="">
              {{ dadosContrato.dataDoContrato }}
            </span>
          </b-col>
          <b-col cols="12" class="mb-25 d-flex flex-column">
            <p class="mb-0 tw-text-base md:tw-text-lg tw-font-bold">Data de Vencimento:</p>
            <span class="">
              {{ dadosContrato.dataVencimento }}
            </span>
          </b-col>
          <b-col cols="12" class="mb-25 mt-1 d-flex flex-column align-items-center justify-content-center">
            <b-button variant="primary" class="w-100 tw-p-1 md:tw-p-10" @click="toggleParcelas">
              {{ mostrarParcelas ? 'Ocultar parcelas' : 'Mostrar parcelas' }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-if="mostrarParcelas" class="tw-mt-4">
      <b-card class="border">
        <h4 class="mb-0 justify-content-center align-items-center text-lg d-flex font-weight-bolder mt-1">Parcelas</h4>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div v-for="item in itemsParcelas" :key="item.parcela">
            <b-row>
              <b-col cols="12">
                <hr />
              </b-col>
              <b-col cols="12">
                <p class="font-weight-bolder mb-25 text-lg">Parcela {{ item.parcela }}</p>
              </b-col>
              <b-col cols="8">
                <div class="d-flex align-items-center">
                  <p class="mb-0">Valor:</p>
                  <span class="ml-25 font-weight-bolder">{{ item.valor }}</span>
                </div>
                <div class="d-flex align-items-center">
                  <p class="mb-0">Vencimento:</p>
                  <span class="ml-25 font-weight-bolder">{{ item.vencimento }}</span>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="d-flex align-items-center">
                  <b-badge v-if="item.status == 'Paga'" pill class="mb-0 alert alert-info rounded-pill">{{
                    item.status
                  }}</b-badge>
                  <b-badge v-else pill class="mb-0 alert alert-warning rounded-pill">{{ item.status }}</b-badge>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
  import { formatarValor, formatarDataCurto } from '@/utils/Functions/Formatacao/Formatacao'

  export default {
    components: {
      formatarDataCurto,
      formatarValor,
    },
    props: {
      isBusy: {},
      dadosCompleto: {},
    },

    data() {
      return {
        dadosTomador: {
          nome: '',
          telefone: '',
          cpf: '',
          foto: '',
        },
        dadosContrato: {
          codigo: '',
          dataDoContrato: '',
          valorContrato: '',
          dataVencimento: '',
          quantidadeParcelas: '',
        },
        fieldsParcelas: ['parcela', 'valor', 'vencimento', 'status'],
        itemsParcelas: [],
        mostrarParcelas: false,
        stepIndex: 0,
        errosAnexo: [],
      }
    },
    async created() {
      await this.montaDadosContrato()
    },
    methods: {
      validaErros() {
        this.$emit('validaErros', this.errosAnexo, this.stepIndex) // Inclua o índice
      },
      montaDadosContrato() {
        this.dadosTomador = {
          nome: this.dadosCompleto.segurado.tomador.nomeCompleto,
          cpf: this.dadosCompleto.segurado.tomador.cpf,
          telefone: this.dadosCompleto.segurado.tomador.telefone,
          foto: this.dadosCompleto.segurado.tomador.foto ? this.dadosCompleto.segurado.tomador.foto.foto : null,
        }

        this.dadosContrato = {
          codigo: this.dadosCompleto.codigo,
          dataDoContrato: formatarDataCurto(this.dadosCompleto.createdAt),
          valorContrato: formatarValor(this.dadosCompleto.valorBaseCalculo),
          dataVencimento: formatarDataCurto(this.dadosCompleto.vencimento),
          quantidadeParcelas: this.dadosCompleto.numeroParcelas,
        }

        this.itemsParcelas = this.dadosCompleto.parcela.map((item) => {
          return {
            parcela: item.parcela,
            valor: formatarValor(item.valor),
            vencimento: formatarDataCurto(item.vencimento),
            status: item.pagamento ? 'Paga' : 'Nâo Paga',
          }
        })
      },
      toggleParcelas() {
        this.mostrarParcelas = !this.mostrarParcelas
      },
    },
  }
</script>

<style>
  /* Media query para telas menores que 576px (mobile) */
  .alert {
    font-size: 12px;
    padding: 10px;
  }
  @media (max-width: 575.98px) {
    .alert {
      font-size: 10px;
      padding: 10px;
    }
  }
</style>
