<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[70vh] md:tw-min-h-[60vh]">
    <div id="anexoDocumentos" class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center lg:tw-mx-24">
      <!-- Dropzone para desktop -->
      <vue2Dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-file-added="adicionarArquivo"
        :useCustomSlot="true"
        class="tw-mb-0.5 tw-py-24"
      >
        <feather-icon icon="UploadCloudIcon" class="align-self-center alert alert-primary p-2 rounded-circle mb-1" size="80" />
        <h3 class="text-primary font-weight-bolder px-1 mb-0">Anexar documento</h3>
        <p class="text-center p-1">
          Arraste e solte ou clique para anexar algum documento oficial com foto (ex: RG, CNH, passaporte e etc)
        </p>
      </vue2Dropzone>

      <!-- Opções de remoção ou carregamento -->
      <div class="d-flex align-items-center justify-content-center text-center mt-2">
        <span v-if="hasFile && !isBusy" @click="removerArquivo" class="alert alert-danger p-2 mb-0 rounded-circle">
          <FeatherIcon size="20" icon="Trash2Icon" />
        </span>
        <span v-else-if="isBusy" class="alert alert-secondary p-2 mb-0 rounded-circle">
          <b-spinner small class="loading-size" label="Small Spinner" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import { normalizeFileName, formatarDataCurto } from '@/utils/Functions/Formatacao/Formatacao'
  import { formatarData } from '@/libs/utils'

  export default {
    props: {
      dadosCompleto: {
        type: Object,
      },
    },
    components: {
      vue2Dropzone,
      useJwt,
      normalizeFileName,
    },
    data() {
      return {
        isBusy: false,
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          maxFilesize: 20,
          maxFiles: 1,
          autoProcessQueue: false,
          acceptedFiles: 'image/*',
        },
        errors: undefined,
        hasFile: false,
        hasErrors: false,
        file: null,
      }
    },
    methods: {
      adicionarArquivo(file) {
        this.hasFile = true

        if (this.$refs.myVueDropzone.dropzone.files.length > this.dropzoneOptions.maxFiles) {
          this.removerArquivo()
          this.errors = 'Quantidade de arquivos excedeu o limite de 1'
          this.hasErrors = true
          this.validateField()
          this.removerArquivo()
          return
        }

        if (!file.type.startsWith('image/')) {
          this.removerArquivo()
          this.errors = 'Anexe apenas arquivos no formato de imagem.'
          this.hasErrors = true
          this.validateField()
          this.removerArquivo()
          return
        }

        this.isBusy = true

        const data = {
          cpf: this.dadosCompleto.segurado.tomador.cpf,
          dataNascimento: this.dadosCompleto.segurado.tomador.dataNascimento,
          nomeCompleto: this.dadosCompleto.segurado.tomador.nomeCompleto,
        }
        const formData = new FormData()
        formData.append('file', file)
        formData.append('originalName', normalizeFileName(file.name))
        formData.append('nomeCompleto', data.nomeCompleto)
        formData.append('dataNascimento', formatarData(data.dataNascimento))
        formData.append('cpf', data.cpf)

        useJwt
          .postDocumento('aws/detectarTextoOCR', () => {}, formData)
          .then(async (response) => {
            const validacao = response.data
            await this.validarInformacoesDocumento(validacao)
          })
          .catch((error) => {
            console.error(error)
            this.errors = 'Erro ao anexar o documento. Tente novamente!'
            this.hasErrors = true
            this.validateField()
          })
          .finally(() => {
            this.isBusy = false
          })
      },

      removerArquivo() {
        this.isBusy = false
        this.hasFile = false
        this.file = null
        this.$refs.myVueDropzone.removeAllFiles()
      },
      async validarInformacoesDocumento(validacao) {
        const { cpfEncontrado, dataNascimentoEncontrado, nomeEncontrado } = validacao

        const validacoes = [
          { campo: cpfEncontrado, nome: 'CPF' },
          { campo: dataNascimentoEncontrado, nome: 'Data de Nascimento' },
          { campo: nomeEncontrado, nome: 'Nome Completo' },
        ]

        for (const validacao of validacoes) {
          if (!validacao.campo) {
            this.errors = `${validacao.nome} indentificado incorreto com base nas informações do Segurado.`
            this.hasErrors = true
            await this.validateField()
            return false
          }
        }
        this.hasErrors = false
        this.errors = undefined
        await this.validateField()
        return true
      },
      validateField() {
        this.$emit('validaDocumentos', {
          hasErrors: this.hasErrors,
          errors: this.errors,
        })
      },
    },
  }
</script>

<style>
  .custom-dropzone {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    margin: 0px !important;
    border: 2px dashed #3949ab !important;
  }

  .dz-progress {
    display: none !important;
  }
</style>
